.o-newsCard {
  $root: &;
  border: 1px solid $gainsboro;
  border-radius: rem(12);
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: all $transition linear;

  &:hover {
    box-shadow: 0 rem(3) rem(6) rem(-4) $black012, 0 rem(6) rem(16) $black008, 0 rem(9) rem(28) rem(8) $black005;
  }

  &_tagContainer {
    flex-wrap: wrap;
    left: rem(16);
    position: absolute;
    top: rem(16);
    z-index: 1;
    @include adjust-flex(flex-start, center);

    &-item {
      border-radius: rem(100);
      margin-right: rem(4);
      padding: rem(1) rem(8);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-img {
    img {
      width: 100%;
    }
  }

  &_content {
    padding: rem(16) rem(16) rem(24) rem(24);

    @include mobile-down {
      padding: rem(16);
    }

    &_dateWrap {
      @include adjust-flex(flex-start, center);

      &-date {
        margin-left: rem(8);
      }
    }

    &_title {
      margin-top: rem(12);
      @include text-overflow;
    }

    &_desc {
      margin-top: rem(8);
      @include text-overflow(4);
    }
  }

  &-hiddenDesc {
    #{$root}_content {
      padding: rem(8) rem(16) rem(15) rem(16);

      @include mobile-down {
        padding: rem(16);
      }

      &_title {
        margin-top: rem(4);
      }

      &_desc {
        display: none;
      }
    }
  }

  &-highlightCard {
    #{$root}_content {
      padding: rem(16) rem(26) rem(24) rem(24);

      @include mobile-down {
        padding: rem(16);
      }

      &_desc {
        margin-top: rem(16);
      }
    }
  }

  &-hiddenDate {
    #{$root}_content {
      padding: rem(16) rem(24) rem(24);

      @include mobile-down {
        padding: rem(16);
      }

      &_dateWrap {
        display: none;
      }

      &_title {
        margin-top: unset;
      }

      &_desc {
        margin-top: rem(8);
      }
    }
  }
}
