.o-section {
  margin-top: rem(60);
  
  @include tablet-down {
    margin-top: rem(40);
  }
  @include mobile-down {
    margin-top: rem(24);
  }

  &-noMarginTop {
    margin-top: 0;
  }

  &-marginBottom {
    margin-bottom: rem(60);
  
    @include tablet-down {
      margin-bottom: rem(40);
    }
    @include mobile-down {
      margin-bottom: rem(24);
    }
  }
}
