// fonts
@font-face {
  font-display: swap;
  font-family: 'iCiel Pacifico';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}iCielPacifico.woff2') format('woff2'), url('#{$font-path}iCielPacifico.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: url('#{$font-path}Montserrat-Bold.woff2') format('woff2'), url('#{$font-path}Montserrat-Bold.woff') format('woff'), url('#{$font-path}Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}Montserrat-Regular.woff2') format('woff2'), url('#{$font-path}Montserrat-Regular.woff') format('woff'), url('#{$font-path}Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: url('#{$font-path}Montserrat-ExtraBold.woff2') format('woff2'), url('#{$font-path}Montserrat-ExtraBold.woff') format('woff'), url('#{$font-path}Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}Montserrat-Medium.woff2') format('woff2'), url('#{$font-path}Montserrat-Medium.woff') format('woff'), url('#{$font-path}Montserrat-Medium.ttf') format('truetype');
}
