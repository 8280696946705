.t-recruitmentList {
  &_search {
    background: $white-smoke;
    border: 1px solid  $gainsboro;
    margin-top: rem(24);
    position: relative;
    z-index: 1;

    @include mobile-up {
      margin-top: rem(40);
    }
  }

  &_searchGroup,
  &_row {
    @include tablet-up {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &_searchGroup {
    padding: rem(24);
  }

  &_pullDownGroup {
    @include tablet-up {
      margin: 0 rem(-10);
    }
  }

  &_pullDown {
    @include tablet-up {
      flex: 0 0 calc(100% - (202 / #{$width-container-value} * 100%));
      max-width: calc(100% - (202 / #{$width-container-value} * 100%));
      padding-right: rem(20);
    }
  }

  &_col {
    @include tablet-up {
      flex: 0 0 calc(100% / 3);
      max-width: calc(100% / 3);
      padding: 0 rem(10);
    }

    @include tablet-down {
      margin-bottom: rem(10);
    }
  }

  &_searchButton {
    @include tablet-up {
      flex: 0 0 calc(202 / #{$width-container-value} * 100%);
      max-width: calc(202 / #{$width-container-value} * 100%);
    }
  }

  &_info {
    margin: rem(20) rem(-32) rem(-16) rem(-16);
    overflow: auto;
    padding: rem(16);
    @include scroll-bars(8);

    @include mobile-up {
      margin-top: rem(40);
    }
  }

  &_title,
  &_item {
    align-items: stretch;
    box-shadow: 0 0 24px 1px $kombu-green01;
    display: inline-flex;
    justify-content: flex-start;
    margin-right: rem(16);
    min-width: rem(768);
    padding: rem(16);
    transition: all $transition linear;
    width: calc(100% - 16px);
    @include mobile-up {
      padding: rem(24);
    }
  }

  &_title {
    background: $ghost-white;
    padding: rem(15) rem(24);
  }

  &_item {
    background: $white;
    cursor: pointer;
    margin-top: rem(20);
    position: relative;

    @include mobile-down {
      margin-top: rem(10);
    }

    &::before {
      background: $saffron;
      border-radius: 0 rem(12) rem(12) 0;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: $transition ease-in-out;
      width: rem(3);
      z-index: 1;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
      box-shadow: 0 0 rem(24) rem(1) $kombu-green03;
    }
  }

  &_position {
    padding-left: rem(8);
    padding-right: rem(8);
    width: 40%;
  }
  
  &_applicationCode,
  &_part,
  &_location,
  &_date {
    padding-left: rem(8);
    padding-right: rem(8);
    width: 15%;
  }

  &_seeMore {
    @include adjust-flex();
    margin-top: rem(40);

    @include mobile-down {
      margin-top: rem(24);
    }
  }

  &_empty {
    margin-top: rem(16);
  }
}
