.t-missionVision {
  $root: &;
  height: 100%;
  
  @include mobile-up {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: rem(-4);
  }

  @include tablet-up {
    margin: rem(-16);
  }

  &_inner {
    height: 100%;
    padding: rem(16);
    transition: $transition all;

    @include mobile-up {
      padding: rem(24);
    }

    @include tablet-up {
      padding: rem(52) rem(56);
    }
  }

  &_item {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
    padding: rem(16);

    @include tablet-down {
      padding: rem(4);
    }

    @include mobile-down {
      flex: 100%;
      max-width: 100%;
      padding: rem(8) 0;
      text-align: center;
    }

    &:hover {
      @include tablet-up {
        #{$root}_inner {
          transform: translateY(-10px);
        }
      }
    }

    @media (hover: none) and (pointer: coarse) {
      /* Smartphones and touchscreens */
      &:hover {
        #{$root}_inner {
          transform: unset;
        }
      }
    }

    &:nth-child(n) {
      #{$root}_inner {
        background-color: $cerise-pink;
      }
    }

    &:nth-child(2n) {
      #{$root}_inner {
        background-color: $vermilion;
      }
    }

    &:nth-child(3n) {
      #{$root}_inner {
        background-color: $tiffany-blue;
      }
    }

    &:nth-child(4n) {
      #{$root}_inner {
        background-color: $rich-electric-blue;
      }
    }
  }

  &_image {
    background-color: $white;
    border-radius: 100%;
    height: rem(70);
    margin-top: rem(16);
    position: relative;
    width: rem(70);

    @include mobile-up {
      height: rem(120);
      width: rem(120);
    }

    @include small-mobile {
      margin-left: auto;
      margin-right: auto;
    }

    .a-image {
      margin: auto;
      max-width: rem(54);

      @include mobile-up {
        max-width: rem(88);
      }
    }
  }

  &_wrapImage {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &_caption {
    margin-top: rem(12);

    @include mobile-up {
      margin-top: rem(24);
    }
  }
}
