.m-breadcrumb {
  background-color: $white;
  padding: rem(16) 0;

  &_wrapper {
    @include adjust-flex(flex-start, center);
    overflow-x: auto;
    @include scroll-bars(8);
  }

  &_item {
    $root: &;
    list-style-type: none;

    &:hover {
      #{$root}_label {
        .a-text {
          text-decoration: underline;
        }
      }
    }

    &:first-child {
      #{$root}_icon {
        display: none;
      }
    }

    &:last-child {
      #{$root}_label {
        .a-text {
          color: $black085;
          font-weight: 400;
        }
      }
    }

    &_wrapper {
      @include adjust-flex;
    }

    &_icon {
      color: $black045;
    }

    &_label {
      transition: all $transition linear;
      white-space: nowrap;
    }
  }
}
