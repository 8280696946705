.t-workplace {
  &_title {
    margin-bottom: rem(40);

    @include tablet-down {
      margin-bottom: rem(32);
    }
    @include mobile-down {
      margin-bottom: rem(24);
    }
  }

  &_desc {
    position: relative;
  }

  &_descContainer {
    background-color: $ghost-white;
    border-radius: rem(20);
    padding: rem(28) rem(32);
    position: relative;
    width: calc(100% - 12px);
    @include adjust-flex(flex-start, flex-start);

    @include mobile-down {
      padding: rem(16);
    }

    @include break-max(374px) {
      .a-heading {
        @include font-base(16, 24);
      }
    }

    &-icon {
      margin-left: rem(8);
      margin-right: rem(32);
      @include mobile-down {
        margin-left: 0;
        margin-right: rem(8);
      }
    }
  }

  &_overlay {
    background-color: $tiffany-blue;
    border-radius: rem(20);
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: rem(12);
    width: 100%;
    z-index: -1;
  }
}
