.o-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: rem(16);
  padding-right: rem(16);

  @include small-mobile-up {
    max-width: rem(540);
  }

  @include mobile-up {
    max-width: rem(720);
  }

  @include tablet-up {
    max-width: rem(960);
  }

  @include desktop-up {
    max-width: rem(1140);
  }

  @include break-min($standard-viewport) {
    max-width: $standard-viewport;
    padding-left: rem(120);
    padding-right: rem(120);
  }

  &-noPaddingRight {
    padding-right: 0;
  }

  &-noPaddingLeft {
    padding-left: 0;
  }

  &-fullscreen {
    padding-left: 0;
    padding-right: 0;
  }
}
