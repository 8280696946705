.a-loading {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: rem(10);
  margin-top: rem(10);

  .a-icon {
    height: rem(30);
    width: rem(30);
  }

  &-fullScreen,
  &-fullfill {
    height: 100vh;
    left: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $z-loading;

    .a-icon {
      height: rem(50);
      width: rem(50);
    }
  }

  &-fullscreen {
    background-color: $white03;
  }

  &-fullfill {
    background-color: $white;
  }

  &_wrapper {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: center;

    .a-text {
      margin-left: rem(8);
    }
  }

  &_tail {
    animation: tail 1s infinite linear;
    background-clip: content-box, border-box;
    background-image: linear-gradient($white, $white), conic-gradient($white, $saffron);
    background-origin: border-box;
    border: double 0.25em transparent;
    border-radius: 100%;
    height: rem(32);
    overflow: hidden;
    position: relative;
    width: rem(32);
  }
}
