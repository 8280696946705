.t-errors {
  background-color: $snow;

  min-height: calc(100vh - #{$header-main-desktop}px - #{$header-sub-desktop}px - 412px);
  padding: rem(40) 0 rem(60);

  @include tablet-down {
    padding-bottom: rem(40);
  }

  @include mobile-down {
    padding: rem(32) 0 rem(24);
  }

  @include mobile-down {
    padding: rem(24) 0;
  }
  
  &_image {
    margin: auto;
    max-width: rem(320);
    width: 100%;
    @include mobile-down {
      max-width: rem(250);
    }
  }

  &_title {
    margin-top: rem(19);

    @include mobile-down {
      margin-top: rem(16);
    }
  }

  &_subTitle {
    margin-top: rem(20);

    @include mobile-down {
      margin-top: rem(16);
    }
  }

  &_button {
    margin-top: rem(40);
    @include adjust-flex();

    @include mobile-down {
      margin-top: rem(24);
    }
  }

  &_btn-home {
    margin-right: rem(20);

    @include mobile-down {
      margin-right: rem(10);
    }
  }
}
