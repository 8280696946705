.t-imageLibrary {
  $root: &;

  &_content {
    display: grid;
    grid-gap: rem(32);
    grid-template-columns: repeat(2, 1fr);
    margin-top: rem(40);

    @include tablet-down {
      grid-gap: rem(16);
      margin-top: rem(30);
    }
    
    @include mobile-down {
      display: flex;
      flex: 0 0 100%;
      grid-gap: unset;
      grid-template-columns: unset;
      height: 100%;
      margin-top: rem(20);
      overflow: auto;
      padding-bottom: rem(4);
      @include scroll-bars(4,$queen-blue, $snow, 0, 0);
    }
  }

  &_block {
    display: grid;
    grid-gap: rem(32);
    grid-template-columns: repeat(2, 1fr);

    @include desktop-down {
      grid-gap: rem(24);
    }

    @include tablet-down {
      grid-gap: rem(16);
    }

    @include mobile-down {
      display: flex;
      flex: 0 0 100%;
    }

    &-odd {
      @include mobile-down {
        margin-left: calc(#{rem(48)} + 200%);
      }
    }

    &_item {
      cursor: pointer;
      overflow: hidden;
      position: relative;

      @include mobile-down {
        flex: 0 0 100%;
      }

      &:hover {
        .a-image {
          transform: scale(1.1);
        }

        &::before {
          transform: translateX(300%) rotate(45deg);
        }
      }

      .a-image {
        transition: $transition-long ease;
      }

      &::before {
        background-color: $white03;
        content: '';
        height: 200%;
        left: 0;
        position: absolute;
        top: -20%;
        transform: translateX(-100%);
        transition: $transition-long ease;
        width: 50%;
        z-index: 1;
      }

      #{$root}_block-even &:nth-child(3) {
        @include small-mobile-up {
          grid-column: 1/span 2;
        }
      }
      #{$root}_block-odd &:first-child {
        @include mobile-up {
          grid-column: 1/span 2;
        }
      }
      #{$root}_block-odd &:nth-child(3) {
        @include mobile {
          grid-column: 1/span 2;
        }
      }
    }
  }

  &_modal {
    &_main {
      margin: auto;
      max-width: rem(737);
      @include tablet-down {  
        max-width: 85%;
      }
      @include small-mobile {
        max-width: 100%;
      }
    }

    &_thumbnail {
      margin: rem(28) auto 0;
      max-width: rem(488);

      @include tablet-down {  
        max-width: 65%;
      }
      @include small-mobile {
        max-width: 75%;
      }

      .slick-list {
        margin-left: rem(-6);
        margin-right: rem(-6);
      }

      .slick-prev.asNav {
        @include mobile-down {
          left: rem(-40);
        }
      }
      
      .slick-next.asNav {
        @include mobile-down {
          right: rem(-40);
        }
      }

      .slick-current {
        .a-image {
          border: 2px solid $white;
        }
      }
      
      &-item {
        cursor: pointer;
        margin-left: rem(6);
        margin-right: rem(6);
      }
    }
  }
}
