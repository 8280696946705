.t-homeBanner {
  position: relative;
  @include aspect-ratio(1366, 600);

  @include tablet-down {
    @include aspect-ratio(2, 1);
  }

  @include mobile-down {
    @include aspect-ratio(1, 1);
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    > div,
    .o-carousel_wrap,
    .o-carousel_item {
      height: 100%;
    }
  }

  &_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &_slider {
    height: 100%;
    position: relative;

    @include mobile-down {
      display: flex;
      flex-direction: column;
    }

    &_title {
      background-color: $rich-electric-blue085;
      border-top-right-radius: rem(20);
      bottom: 0;
      max-width: rem(957);
      padding: rem(10) rem(10) rem(10) rem(40);
      position: absolute;
      width: 100%;
      @include adjust-flex(space-between, center);

      @include mobile-down {
        flex: 1;
        padding: rem(10);
      }

      .a-heading {
        @include mobile-down {
          @include text-overflow(3);
        }
      }

      &_iconWrap {
        background-color: $black02;
        border-radius: rem(100);
        cursor: pointer;
        height: rem(60);
        margin-left: rem(8);
        position: relative;
        width: rem(60);

        @include tablet-down {
          height: rem(40);
          width: rem(40);
        }

        @include mobile-down {
          flex: 1 0 auto;
          height: rem(32);
          width: rem(32);
        }

        &-icon {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
