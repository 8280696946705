.t-newsDetail {
  &_time {
    margin-top: rem(24);

    @include mobile-down {
      margin-top: rem(16);
    }
  }

  &_heading {
    margin-top: rem(8);
  }

  &_content {
    margin-top: rem(20);

    @include mobile-down {
      margin-top: rem(16);
    }

    img {
      max-width: 100%;
    }
  }

  &_tags {
    display: flex;
    flex-wrap: wrap;
    margin: rem(20) rem(-4) 0;

    @include mobile-down {
      margin-top: rem(8);
    }
  }

  &_items {
    background: $alice-blue;
    border: 1px solid $rich-electric-blue;
    border-radius: rem(100);
    margin: rem(12) rem(4) 0;
    padding: 0 rem(7);
  }
}
