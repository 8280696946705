.o-carousel {
  $root: &;
  position: relative;

  &_dot {
    background-color: $blue-yonder03;
    border-radius: 1px;
    display: block;
    height: rem(3);
    transition: $transition-long ease;
    width: rem(16);
  }

  &_arrow {
    $arrow: &;
    background: $saffron;
    border-radius: rem(100);
    height: rem(40);
    transition: $transition-long ease;
    width: rem(40);
    z-index: $z-1;

    @include tablet-down {
      height: rem(32);
      width: rem(32);
    }

    @include mobile-down {
      height: rem(24);
      width: rem(24);
    }

    &:hover {
      background-color: $saffron07;
    }

    &::before {
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: rem(15);
      content: '';
      display: block;
      height: 100%;
      opacity: 1;
      transition: $transition-long ease;
      width: 100%;
      @include tablet-down {
        background-size: rem(20);
      }
    }

    &_prev {
      left: rem(-72);

      &::before {
        background-image: url('~assets/icons/ic_arrow_back_white.svg');
      }
    }

    &_next {
      right: rem(-72);

      &::before {
        background-image: url('~assets/icons/ic_arrow_next_white.svg');
      }
    }

    &.banner {
      &#{$arrow}_prev {
        left: rem(50);
        @include mobile-down {
          left: rem(30);
        }
      }
      &#{$arrow}_next {
        right: rem(50);
        @include mobile-down {
          right: rem(30);
        }
      }
    }

    &.upcomingProduct {
      &#{$arrow}_prev {
        left: rem(32);
        @include mobile-down {
          left: rem(16);
        }
      }
      &#{$arrow}_next {
        right: rem(32);
        @include mobile-down {
          right: rem(16);
        }
      }
    }

    // Modifier blue arrow
    &.blue {
      background: $blue-yonder;

      &:hover {
        background-color: $blue-yonder07;
      }

      &-square {
        border-radius: unset;
      }
    }

    // Modifier asNav carousel type
    &.asNav {
      background: none;
      border: 0;
      border-radius: unset;

      &::before {
        background-size: rem(24);
      }

      &#{$arrow}_prev {
        left: rem(-62);

        &::before {
          background-image: url('~assets/icons/ic_arrow_back_ios.svg');
        }
      }

      &#{$arrow}_next {
        right: rem(-62);

        &::before {
          background-image: url('~assets/icons/ic_arrow_forward_ios.svg');
        }
      }
    }

    &.slick-arrow {
      &.slick-disabled {
        background: $ghost-white;
        cursor: no-drop;

        &::before {
          opacity: 1;
        }

        // Modifier disabled blue arrow
        &.blue {
          background: $white;
          border: 1px solid $azureish-white;
        }

        // Modifier disabled yellow-outline arrow
        &.yellow-outline {
          background: $white;
          border: 1px solid $azureish-white;
        }

        // Modifier disabled asNav arrow
        &.asNav {
          background: none;
        }
      }

      // Modifier yellow icon arrow (default)
      &.slick-disabled#{$arrow}_prev {
        &::before {
          background-image: url('~assets/icons/ic_arrow_back_yellow.svg');
        }
      }

      &.slick-disabled#{$arrow}_next {
        &::before {
          background-image: url('~assets/icons/ic_arrow_next_yellow.svg');
        }
      }

      // Modifier blue icon arrow
      &.slick-disabled.blue#{$arrow}_prev {
        &::before {
          background-image: url('~assets/icons/ic_arrow_back_blue.svg');
        }
      }

      &.slick-disabled.blue#{$arrow}_next {
        &::before {
          background-image: url('~assets/icons/ic_arrow_next_blue.svg');
        }
      }

      // Modifier asNav icon arrow (ios icon type)
      &.slick-disabled.asNav#{$arrow}_prev {
        &::before {
          background-image: url('~assets/icons/ic_arrow_back_ios.svg');
        }
      }

      &.slick-disabled.asNav#{$arrow}_next {
        &::before {
          background-image: url('~assets/icons/ic_arrow_forward_ios.svg');
        }
      }
    }
  }

  .slick-slide {
    margin-bottom: -4px;
    outline: none;

    div {
      &:focus {
        outline: 0;
      }
    }
  }

  .slick-active {
    #{$root}_dot {
      background-color: $blue-yonder;
      border-radius: 4px;
      width: rem(32);
    }
  }

  .slick-dots {
    bottom: 0;
    display: flex !important;
    justify-content: center;
    margin-top: rem(32);
    position: relative;

    @include mobile-down {
      margin-top: rem(24);
    }

    li {
      display: block;
      height: auto;
      margin: 0 3px;
      width: auto;
    }
  }
}
