.o-modal {  
  $root: &;
  border: 0;
  border-radius: rem(8);
  height: 100%;
  outline: 0;
  position: fixed;
  width: 100%;

  &:focus {
    outline: 0;
  }

  &_close {
    background-color: transparent;
    position: absolute;
    right: rem(28);
    top: rem(28);
    z-index: $z-1;
    @include reset-button();

    @include tablet-down {
      right: rem(8);
      top: rem(8);
    }
  }

  &_main {
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding-left: rem(16);
    padding-right: rem(16);
    width: 100%;
  }

  &_wrapper {
    background-color: $rich-electric-blue;
    box-shadow: 0 0 rem(24) rem(1) $kombu-green01;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-height: calc(100vh - #{rem(120)});
    max-width: rem(740);
    overflow: hidden;
    padding-top: rem(40);
    position: relative;
    width: 100%;

    @include tablet-down {
      max-height: calc(100vh - #{rem(60)});
    }

    @include mobile-down {
      max-height: calc(100vh - #{rem(48)});
    }
  }

  &_body {
    flex: 1;
    margin-top: rem(20);
    overflow-y: auto;
    padding: 0 rem(40) rem(24);
    @include scroll-bars(4, $gainsboro, $snow, 0, 0);

    @include tablet-down {
      margin-top: rem(12);
      padding: 0 rem(16) rem(16);
    }
  }

  &_header {
    padding-left: rem(64);
    padding-right: rem(64);
    
    @include mobile-down {
      padding-left: rem(16);
      padding-right: rem(16);
    }
  }

  //* Modifier
  &-jdModal {
    #{$root}_body {
      margin-top: rem(20);
      padding: 0 rem(60) rem(28);

      @include tablet-down {
        padding: 0 rem(32) rem(16);
      }

      @include mobile-down {
        padding: 0 rem(16) rem(16);
      }
    }
  }

  &-recruitModal {
    #{$root}_body {
      margin-top: rem(32);
      padding: 0 rem(60) rem(28);

      @include tablet-down {
        margin-top: rem(24);
        padding: 0 rem(32) rem(16);
      }

      @include mobile-down {
        padding: 0 rem(16) rem(16);
      }
    }
  }

  &-library {
    #{$root}_wrapper {
      background-color: $saffron;
      max-width: rem(1126);
      overflow-y: auto;
    }
    #{$root}_body {
      margin-top: 0;
      overflow-y: visible;
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.ReactModal__Overlay {
  background: rgba($black, 0.3);
  inset: 0;
  opacity: 0;
  position: fixed;
  transform: translateY(-100%);
  transition: all 500ms ease-in-out;
  z-index: $z-modal;
  
  &::after {
    background: rgba($black, 0.74);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  
  &--open {
    overflow: hidden;
    @include tablet-up {
      padding-right: rem(7);
    }
  }
  
  &--after-open {
    opacity: 1;
    transform: translateY(0);
  }
  
  &--before-close {
    opacity: 0;
    transform: translateY(-100%);
  }
}
