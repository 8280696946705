.t-policy {
  background-color: $snow;
  background-image: url('~assets/images/policy_bg.png'), url('~assets/images/policy_bg_1.png');
  background-position: left bottom, top left;
  background-repeat: no-repeat, no-repeat;
  background-size: contain, calc(255 / 1366 * 100%), auto;
  padding-bottom: calc(78 / 1366 * 100%);
  padding-top: rem(52);

  @include tablet-down {
    padding-bottom: rem(48);
    padding-top: rem(36);
  }

  @include mobile-down {
    padding-bottom: rem(32);
    padding-top: rem(34);
  }

  &_title {
    margin-bottom: rem(32);

    @include mobile-down {
      margin-bottom: rem(24);
    }
  }

  &_desc {
    &-content {
      @include font-base(16,24);
      @include mobile-down {
        @include font-base(14, 22);
      }

      h3 {
        @include font-base(24, 32);
        color: $raisin-black;
        font-weight: 600;
        margin-top: 0;
        @include tablet-down {
          @include font-base(20, 30);
        }
      }

      ul {
        list-style: none;
        padding-left: rem(16);
      }
    }
  }
}
