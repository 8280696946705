$dream-colors: (
  $blue-yonder,
  $cerise-pink,
  $vermilion,
  $saffron,
  $tiffany-blue,
  $rich-electric-blue,
  $grape,
);

.t-highlightNumbers {
  $root: &;
  background-color: $snow;
  padding-bottom: rem(45);
  padding-top: rem(60);

  @include tablet-down {
    padding-bottom: rem(32);
    padding-top: rem(40);  
  }

  @include mobile-down {
    padding-bottom: rem(24);
    padding-top: rem(24);  
  }

  &_block {
    @include mobile-down {
      text-align: center;
    }

    &-desc {
      margin-top: rem(8);
    }
  }

  &_list {
    @include adjust-flex(flex-start, flex-start);
    flex-wrap: wrap;
    margin: rem(16) rem(-16) rem(-16);

    @include tablet-down {
      margin: rem(16) rem(-8) rem(-8);
    }

    &_item {
      flex: 0 0 25%;
      padding: rem(16);

      @include tablet-down {
        padding: rem(8);
      }

      @include mobile-down {
        flex: 0 0 50%;
      }

      @include break-max(374px) {
        flex: 0 0 100%;
        text-align: center;
      }

      @for $i from 1 through length($dream-colors) {
        &:nth-child(#{length($dream-colors)}n + #{$i}) {
          #{$root}_block-number {
            color: nth($dream-colors, $i);
          }
        }
      }
    }
  }

  &_seeMore {
    margin-top: rem(32);
    @include adjust-flex();

    @include mobile-down {
      margin-top: rem(24);  
    }
  }
}
