.o-footer {
  $width-inner-container: 1126;

  &_top {
    padding: rem(80) 0 rem(76);
    position: relative;

    @include desktop-down {
      padding: rem(60) 0;
    }

    @include tablet-down {
      padding: rem(30) 0;
    }

    &-img {
      bottom: 0;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    &::before {
      background: $gamboge-linear;
      bottom: 0;
      content: '';
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    &_wrap {
      @include adjust-flex(space-between,stretch);
      flex-wrap: wrap;
      position: relative;
      z-index: 2;
    }

    &_image {
      padding-top: rem(13);
      width: calc(148 / #{$width-inner-container} * 100%);

      @include tablet-down {
        margin-bottom: rem(20);
        width: 100%;
      }
    }
       
    &_logo {
      max-width: rem(148);
      @include tablet-down {
        margin: 0 auto;
      }
    }

    &_info {
      width: calc(353 / #{$width-inner-container} * 100%);
      @include tablet-down {
        width: 40%;
      }
      @include mobile-down {
        margin-top: rem(16);
        order: 4;
        width: 100%;
      }
    }

    &_relate,
    &_about {
      width: calc(258 / #{$width-inner-container} * 100%);
      @include tablet-down {
        width: 24%;
      }
      @include mobile-down {
        width: 48%;
      }

      @include small-mobile {
        width: 100%;
      }
    }

    &_relate {
      ul {
        list-style: none;
        margin-top: rem(16);
      }

      li {
        margin-top: rem(8);
      }

      &-link {
        @include adjust-flex(flex-start);
      }

      .a-icon {
        flex-shrink: 0;
        margin-right: rem(8);
      }
    }

    &_about {
      ul {
        list-style: none;
      }

      li {
        margin-top: rem(20);

        &:first-child {
          margin-top: 0;
        }
        @include tablet-down {
          margin-top: rem(16);
        }
      }

      @include small-mobile {
        margin-top: rem(16);
      }
    }

    &_email,
    &_phone,
    &_social {
      margin-top: rem(24);
      @include tablet-down {
        margin-top: rem(16);
      }
      @include mobile-down {
        margin-top: rem(10);
      }
    }

    &_social {
      &-item {
        display: inline-block;
        margin-right: rem(24);
        width: rem(24);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &_bottom {
    background-color: $blue-yonder;
    padding: rem(6) 0;

    &_wrap {
      @include adjust-flex;
      flex-wrap: wrap;
    }

    &_policy,
    &_security {
      margin-right: calc(60 / #{$width-inner-container} * 100%);
      @include mobile-down {
        margin: 0 0 rem(6);
        width: 100%;
      }
    }

    &_copyright {
      @include mobile-down {
        width: 100%;
      }
    }
  }
}
