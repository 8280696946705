.m-pulldown {
  $root: &;
  position: relative;

  &_label {
    color: $white-smoke;
    margin-bottom: rem(8);
    @include font-base(12, 16);
    @include adjust-flex(flex-start, center);

    &-required {
      color: $white-smoke;
      margin-left: rem(4);
    }

    .a-icon {
      &:not(#{$root}_icon-info) {
        margin-left: rem(4);
      }

      #{$root}_icon-info & {
        margin-right: rem(4);
      }
    }
  }

  &_header {
    background: $white;
    color: $taupe-gray;
    cursor: pointer;
    height: rem(32);
    position: relative;
    transition: all $transition linear;
    width: 100%;
    @include adjust-flex(flex-end, stretch);
    @include font-base(14, 22);

    &_content {
      align-items: center;
      display: flex;
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
      padding: rem(5) rem(12);
      text-overflow: ellipsis;
      width: calc(100% - 26px);

      span {
        @include text-overflow(1);
      }
    }
  }

  &_placeholder {
    @include font-base(14, 22);
  }

  &_search {
    background: transparent;
    border: 0;
    color: $taupe-gray;
    font-size: rem(14);
    height: 100%;
    left: 0;
    padding: rem(7.2) rem(12);
    position: absolute;
    top: 0;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &_arrow {
    flex-shrink: 0;
    margin-right: rem(8);
    position: relative;
    width: rem(16);

    &::after {
      background: url('~assets/icons/ic_triagle_down_crsenic.svg') no-repeat;
      background-position: center;
      background-size: rem(14);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: $transition;
      width: 100%;

      #{$root}-triangleGhostWhite & {
        background: url('~assets/icons/ic_triagle_down_ghost_white.svg') no-repeat;
        background-position: center;
        background-size: rem(6);
      }
    }

    &.opened {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &_wrapper {
    background: $white;
    border-radius: rem(8);
    box-shadow: 0 3px 6px -4px $black012, 0 6px 16px $black008, 0 9px 28px 8px $black005;
    margin-top: rem(4);
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &_list {
    color: $taupe-gray;
    margin: 0;
    max-height: rem(300);
    overflow-y: auto;
    padding: 0;
    @include font-base(14, 22);
    @include scroll-bars(8);
  }

  &_item {
    cursor: pointer;
    font-weight: 400;
    list-style: none;
    padding: rem(5) rem(12);
    transition: all $transition linear;
    white-space: normal;

    &:first-child,
    &:last-child {
      margin-top: rem(4);
    }

    &:hover {
      background: $saffron;
      color: $white;
    }

    &.none {
      color: $gray-x11;
      text-align: center;
    }
  }

  &-outlined {
    #{$root}_header {
      background: $white;
      border: 1px solid $gainsboro;
      border-radius: rem(8);
      color: $raisin-black;
      font-weight: 400;
      height: rem(32);
      @include font-base(14, 20);

      &:hover {
        border-color: $raisin-black;
      }
    }

    #{$root}_placeholder {
      color: $taupe-gray;
    }

    #{$root}_item {
      color: $taupe-gray;

      &:hover {
        color: $white;
      }
    }
  }

  &-radius {
    #{$root}_header {
      border: 1px solid $gainsboro;
      border: 1px solid $gainsboro;
      border-radius: rem(100);
      color: $raisin-black;
      height: rem(32);

      &:hover {
        border-color: $raisin-black;
      }
    }

    #{$root}_placeholder {
      color: $raisin-black;
    }

    #{$root}_item {
      color: $raisin-black;

      &:hover {
        color: $white;
      }
    }
  }

  &-triangleGhostWhite {
    #{$root}_header {
      border: 1px solid $gray-x11;
      border-radius: rem(100);
      color: $gray-x11;
      height: rem(24);

      &:hover {
        border-color: $white-smoke;
      }
    }

    #{$root}_header_content {
      padding: 0 rem(18) 0 rem(8);
    }

    #{$root}_placeholder {
      color: $gray-x11;
    }

    #{$root}_item {
      color: $gray-x11;
      padding: rem(2) rem(12);

      &:hover {
        color: $white;
      }
    }
  }

  &-error {
    #{$root}_header {
      border-color: $sinopia;
    }

    #{$root}_error {
      @include font-base(12, 18);
      color: $sinopia;
      font-weight: 400;
      margin-top: rem(4);
      opacity: 0.8;
    }
  }
}
