.a-heading {
  margin: 0;
  @include font-base(32, 48);
  @include tablet-down {
    @include font-base(24, 32);
  }
  @include mobile-down {
    @include font-base(20, 30);
  }

  &-20x30 {
    @include font-base(20, 30);
    @include mobile-down {
      @include font-base(16, 24);
    }
  }

  &-24x32 {
    @include font-base(24, 32);
    @include tablet-down {
      @include font-base(20, 30);
    }
    @include mobile-down {
      @include font-base(16, 24);
    }
  }

  &-38x52 {
    @include font-base(38, 52);
    @include tablet-down {
      @include font-base(32, 48);
    }
    @include mobile-down {
      @include font-base(24, 32);
    }
  }

  &-32x48 {
    @include font-base(32, 48);
    @include tablet-down {
      @include font-base(24, 32);
    }
    @include mobile-down {
      @include font-base(20, 30);
    }
  }

  @include style-color;
  @include style-general;
  @include style-font-weight;
  @include style-font-family;
}
