.o-header {
  $root: &;
  background-color: $white;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-header;

  &_wrapMain {
    background-color: $snow;
    height: rem($header-main-desktop);
    @include adjust-flex();

    @include tablet-down {
      height: rem($header-main-tablet);
    }

    .o-container {
      width: 100%;
    }
  }

  &_wrapSub {
    background-color: $white;
    bottom: rem(-$header-sub-desktop);
    box-shadow: 0 3px 6px -4px $black012, 0 6px 16px $black008, 0 9px 28px 8px $black005;
    height: rem($header-sub-desktop);
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    transition: $transition all;
    z-index: -1;
    @include adjust-flex();

    #{$root}-scroll & {
      @include tablet-up {
        bottom: 0;
      }
    }

    @include tablet-down {
      background-color: $snow;
      display: block;
      flex-direction: column-reverse;
      height: 100vh;
      max-height: 100vh;
      overflow: auto;
      position: fixed;
      right: 0;
      top: 0;
      transform: translateX(100%);
      transition: $transition-long linear;
      width: 100%;
      z-index: 0;
    }

    #{$root}-open & {
      transform: translateX(0);
    }

    .o-container {
      width: 100%;
    }
  }

  &_main {
    @include adjust-flex(space-between);

    &_hamburger {
      display: none;
      min-width: rem(84);
      @include tablet-down {
        display: block;
        min-width: unset;
      }
    }

    &_left {
      flex-grow: 1;
      @include adjust-flex(space-between, center);
      @include tablet-down {
        justify-content: center;
      }
    }

    &_menu {
      @include tablet-down {
        display: none;
      }
    }

    &_logo {
      width: rem(105);
      @include tablet-down {
        margin-left: rem(-24);
        width: rem(90);
      }
      @include small-mobile {
        margin-left: rem(-24);
        width: rem(75);

      }
    }

    &_btn-buy {
      min-width: rem(84);
    }
  }

  &_sub {
    @include adjust-flex(space-between);

    @include tablet-down {
      flex-direction: column-reverse;
      justify-content: flex-end;
      padding-bottom: rem(50);
    }

    &_left {
      @include adjust-flex();
      @include tablet-down {
        width: 100%;
      }
    }

    &_menu {
      display: none;
      @include tablet-down {
        display: block;
        width: 100%;
      }
    }

    &_right {
      @include adjust-flex();
      @include tablet-down {
        flex-direction: column-reverse;
        width: 100%;
      }
    }

    &_input {
      min-width: rem(264);
      @include tablet-down {
        width: 100%;
      }
    }

    &_util {
      @include tablet-down {
        height: rem($header-main-tablet);
        width: 100%;
        @include adjust-flex(space-between);
      }
    }

    &_hamburger {
      display: none;
      @include tablet-down {
        display: block;
      }
    }

    &_language {
      .m-pulldown {
        &_arrow {
          margin: 0;
        }

        &_header_content {
          padding: 0 rem(4) 0 rem(8);
        }
      }
    }
  }

  &_info {
    @include adjust-flex();

    @include tablet-down {
      margin-top: rem(40);
    }

    @include mobile-down {
      display: block;
      width: 100%;
    }

    &_mail,
    &_phone {
      .a-link {
        @include adjust-flex();
        @include mobile-down {
          justify-content: flex-start;
        }
      }

      .a-icon {
        margin-right: rem(8);
      }
    }

    &_mail {
      @include mobile-down {
        margin-bottom: rem(16);
      }
    }
  }

  &_separate {
    background-color: $anti-flash-white;
    height: rem(20);
    margin: 0 rem(24);
    width: 1px;
    @include desktop-down {
      margin: 0 rem(16);
    }

    #{$root}_sub_right & {
      @include tablet-down {
        display: none;
      }
    }

    #{$root}_info & {
      @include mobile-down {
        display: none;
      }
    }
  }
}

.hamburger {
  cursor: pointer;

  span {
    background-color: $vermilion;
    display: block;
    height: rem(2);
    margin-bottom: rem(3);
    position: relative;
    transition: $transition-long ease;
    width: rem(24);

    &:nth-child(1) {
      transform-origin: left;
    }

    &:nth-child(2) {
      transform-origin: center;
    }

    &:nth-child(3) {
      transform-origin: left;
    }
  }

  &-active {
    span {
      &:nth-child(1) {
        left: 0;
        top: rem(-1);
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        right: rem(4);
        top: rem(2);
        transform: rotate(315deg);
      }

      &:nth-child(3) {
        transform: scaleX(0);
      }
    }
  }
}

.menu {
  @include adjust-flex(flex-start);
  flex-grow: 1;
  list-style: none;
  margin: 0 rem(56);
  @include font-base(16, 24);

  &-isHideBtn {
    margin: 0;
  }

  @include desktop-down {
    margin: 0 rem(30);
  }

  @include tablet-down {
    display: block;
    margin: 0;
  }
}

.menu-item {
  $root: &;
  cursor: pointer;

  margin-right: rem(40);
  position: relative;

  .a-link {
    opacity: 1;
  }

  @include desktop-down {
    margin-right: rem(20);
  }

  @include tablet-down {
    margin-right: 0;
    margin-top: rem(30);
  }

  &:last-child {
    margin-right: 0;
  }

  &_line {
    background-color: $vermilion;
    content: '';
    height: 2px;
    left: 50%;
    position: absolute;
    top: calc(100% + 6px);
    transform: translateX(-50%);
    transition: $transition ease-in-out;
    width: 0;
    @include tablet-down {
      display: none;
    }

    #{$root}:hover &,
    #{$root}-active & {
      width: 100%;
    }
  }

  &-isChild {
    margin: 0;
    @include font-base(14, 22);

    @include tablet-down {
      margin-top: rem(16);
    }
  }

  &_title {
    color: inherit;
  }

  &_link {
    color: $davys-grey1;
    display: block;
    position: relative;
    transition: $transition ease-in-out;

    #{$root}-hasChild:not(#{$root}-isChild) > &::before {
      @include tablet-down {
        border-left: rem(5) solid transparent;
        border-right: rem(5) solid transparent;
        border-top: rem(5) solid $davys-grey1;
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: $transition-long linear;
      }
    }

    #{$root}-isChild:is(#{$root}-hasChild) > &::before {
      @include tablet-down {
        border-left: rem(5) solid transparent;
        border-right: rem(5) solid transparent;
        border-top: rem(5) solid $davys-grey1;
        content: '';
        height: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: $transition-long linear;
        width: 0;
      }
    }

    #{$root}_list-deep &::before {
      display: none;
    }

    #{$root}-expand > &::before {
      @include tablet-down {
        transform: translateY(-50%) rotate(180deg) !important;
      }
    }

    #{$root}-active > &::before {
      @include tablet-down {
        border-top-color: $vermilion !important;
      }
    }

    #{$root}:hover > & {
      @include tablet-up {
        color: $vermilion;
        font-weight: 500;
      }
    }

    #{$root}-active > & {
      color: $vermilion;
      font-weight: 500;
    }

    #{$root}-isChild > & {
      @include tablet-up {
        border-radius: rem(4);
        padding: rem(8) rem(8) rem(8) rem(16);
      }
    }

    #{$root}-isChild:is(#{$root}-active) > &,
    #{$root}-isChild:hover > & {
      @include tablet-up {
        background-color: $vermilion;
        color: $white;
        font-weight: 500;
        opacity: 1;
      }
    }
  }

  &_wrap {
    max-height: 0;
    min-width: rem(215);
    overflow: hidden;
    transition: $transition-fast ease-in-out;
    visibility: hidden;
    z-index: 1;

    @include desktop-down {
      transition: $transition ease-in-out;
    }

    @include tablet-up {
      left: 0;
      padding-top: rem(16);
      position: absolute;
      top: 100%;
    }

    #{$root}-expand > & {
      @include tablet-down {
        max-height: rem(1000);
        overflow: initial;
        visibility: visible;
      }
    }

    #{$root}:hover > & {
      @include tablet-up {
        max-height: rem(1000);
        overflow: initial;
        visibility: visible;
      }
    }

    #{$root}_list & {
      padding-top: 0;

      @include tablet-up {
        left: 100%;
        padding-left: rem(6);
        top: 0;
      }
    }
  }

  &_list {
    list-style: none;

    @include tablet-down {
      margin-left: rem(30);
    }

    @include tablet-up {
      background: $snow;
      border-radius: rem(12);
      box-shadow: 0 rem(30) rem(84) $black0043, 0 rem(8) rem(32) $black0043, 0 rem(3) rem(14) $black0043,
        0 1px rem(13) $black0043;
      padding: rem(8) 0;
    }

    &-deep {
      @include tablet-up {
        box-shadow: 0 3px 6px -4px $black012, 0 6px 16px $black008, 0 9px 28px 8px $black005;
      }
      @include tablet-down {
        margin-left: rem(20);
      }
    }
  }
}
