.t-jdModal {
  position: relative;

  &_layer {
    background-image: url('~assets/images/jd_modal_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    @include mobile-down {
      background-size: 100%;
    }
  }

  &_footer {
    background-color: $medium-persian-blue;
    padding: rem(13) rem(60);
    @include adjust-flex(flex-end, center);

    @include tablet-down {
      padding: rem(13) rem(32);
    }

    @include mobile-down {
      padding: rem(13) rem(16);
    }
  }
}
