.t-upcomingProduct {
  $root: &;
  position: relative;

  &_heading {
    margin-bottom: rem(40);

    @include mobile-down {
      margin-bottom: rem(24);
    }
  }

  &_item {
    position: relative;
    transition: all $transition linear;

    &:hover {
      box-shadow: 0 rem(3) rem(6) rem(-4) $black012, 0 rem(6) rem(16) $black008, 0 rem(9) rem(28) rem(8) $black005;
    }

    @include mobile-up {
      margin-right: rem(32);
    }
  }

  &_body {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &_wrap {
    padding: rem(12);
    position: relative;
  }

  &_backgroundOverlay {
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &_content {
    position: relative;
    z-index: $z-1;
  }

  .a-text {
    margin-top: rem(8);
  }

  .a-link {
    color: inherit;
  }

  .slick-slide {
    position: relative;
  }
}
