.a-button {
  $root: &;
  @include reset-button();
  @include adjust-flex();
  border-radius: rem(100);
  box-shadow: 0 rem(2) 0 $black0043;
  font: inherit;
  overflow: hidden;
  padding: rem(4) rem(16);
  position: relative;
  transition: all $transition linear;
  width: 100%;
  @include font-base(14, 22);

  &-primary {
    background-color: $saffron;
    border: 1px solid $saffron;
    color: $white;
    
    &:hover {
      background-color: $white;
      color: $saffron;
    }
  }

  &-secondary {
    background-color: $white;
    border: 1px solid $white;
    color: $saffron;

    &:hover {
      background-color: $saffron;
      color: $white;
    }
  }

  &-orange {
    background-color: $vermilion;
    border: 1px solid $vermilion;
    color: $white;
    
    &:hover {
      background-color: $saffron;
      border-color: $saffron;
      color: $white;
    }
  }

  &-outline {
    background-color: $white;
    border: 1px solid $gainsboro;
    box-shadow: 0 rem(2) 0 $black0016;
    color: $black085;
    
    &:hover {
      background-color: $white-smoke;
    }
  }

  &:disabled,
  &-loading {
    cursor: not-allowed;
    &#{$root}-primary {
      background-color: rgba($saffron, 0.5);
      border-color: rgba($saffron, 0.5);
      color: $white;
    }

    &#{$root}-secondary {
      background-color: rgba($white, 0.5);
      border-color: rgba($white, 0.5);
      color: $saffron;
    }

    &#{$root}-outline {
      background-color: rgba($gainsboro, 0.2);
      border-color: rgba($gainsboro, 0.2);
    }
  }

  &-lg {
    padding: rem(7) rem(16);
    @include font-base(16, 24);

    @include mobile-down {
      @include font-base(14, 22);
    }
  }

  &-inline {
    width: unset;
  }
}
