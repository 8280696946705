.t-homeIntroduction {
  align-items: center;
  display: flex;
  justify-content: space-between;
  @include tablet-down {
    flex-direction: column;
  }

  &_lineVertical {
    border-left: rem(8) solid $saffron;
    padding-left: rem(28);
    position: relative;
    z-index: -1;
    @include tablet-down {
      padding-left: rem(15);
    }

    &::before {
      border-left: rem(8) solid $cerise-pink;
      content: '';
      height: 100%;
      left: rem(-8);
      max-height: calc(100% / 3);
      position: absolute;
    }

    &::after {
      border-left: rem(8) solid $rich-electric-blue;
      bottom: 0;
      content: '';
      height: 100%;
      left: rem(-8);
      max-height: calc(100% / 3);
      position: absolute;
    }
  }

  &_wrapText {
    flex: 0 0 49%;
    @include desktop-down {
      flex: 0 0 40%;
    }
    @include tablet-down {
      margin-top: rem(24);
      order: 2;
    }

    @include mobile-down {
      margin-top: rem(16);
    }
  }

  &_description {
    margin-top: rem(20);
  }

  &_wrapImage {
    flex: 0 0 50%;
    position: relative;
    @include desktop-down {
      flex: 0 0 55%;
    }
    @include tablet-down {
      flex: 0 0 100%;
      order: 1;
      width: 75%;
    }

    @include mobile-down {
      width: 100%;
    }
  }

  &_background {
    left: 0;
    margin: rem(24) 0 0 rem(219);
    max-width: rem(273);
    position: absolute;
    right: 0;
    top: 0;
    @include small-mobile {
      margin: rem(24) 0 0 rem(140);
    }
  }

  &_imageBig {
    border-radius: 50%;
    max-width: calc(367 / 547 * 100%);
    overflow: hidden;
  }

  &_imageSmall {
    border: rem(10) solid $white;
    border-radius: 50%;
    left: 0;
    max-width: calc(235 / 547 * 100%);
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(calc(55px + 100%), -53%);
    @include small-mobile {
      border: rem(6) solid $white;
      transform: translate(calc(35px + 100%), -32%);
    }
  } 
}
