.t-contactForm {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &_content {
    background-color: $vermilion09;
    padding: rem(52) 0 rem(56);

    @include tablet-down {
      padding: rem(40) 0;
    }

    @include mobile-down {
      padding: rem(24) 0;
    }
  }

  &_form {
    background-color: $raisin-black03;
    border-radius: rem(12);
    margin: rem(40) auto 0;
    max-width: rem(740);
    padding: rem(48) rem(64);
    @include tablet-down {
      margin-top: rem(32);
      padding: rem(32) rem(48);
    }

    @include mobile-down {
      margin-top: rem(24);
      padding: rem(24) rem(16);
    }
  }

  &_row {
    display: flex;
    flex-wrap: wrap;
    margin: rem(-32) rem(-12) 0;
    @include mobile-down {
      margin-top: rem(-16);
    }
  }

  &_col {
    flex: 0 0 50%;
    margin-top: rem(32);
    padding: 0 rem(12);

    @include mobile-down {
      flex: 0 0 100%;
      margin-top: rem(16);
    }

    &.fullWidth {
      flex: 0 0 100%;
    }
  }

  &_button {
    margin: rem(40) auto 0;
    max-width: rem(112);
    
    @include tablet-down {
      margin-top: rem(30);
    }

    @include mobile-down {
      margin-top: rem(24);
    }
  }
}
