// Primary Colors
$british-racing-green: rgb(7, 76, 49); // #074c31
$android-green: rgb(161, 192, 56); // #a1c038

// Text Colors
$raisin-black: rgb(38, 38, 38); // #262626
$davys-grey: rgb(90, 90, 90); // #5A5A5A
$gray-x11: rgb(191, 191, 191); // #bfbfbf
$white-smoke: rgb(245, 245, 245); // #f5f5f5
$snow: rgb(250, 250, 250); // #fafafa
$white: rgb(255, 255, 255); // #ffffff
$arsenic: rgb(67, 67, 67); //#434343
$saffron: rgb(252, 189, 48); //#fcbd30
$rich-electric-blue: rgb(0, 132, 212); //#0084d4
$cerise-pink: rgb(230, 56, 136); //#e63888
$vermilion: rgb(222, 59, 33); //#de3b21
$tiffany-blue: rgb(0, 191, 178); //#00bfb2
$blue-yonder: rgb(77, 102, 164); //#4d66a4
$black: rgb(0, 0, 0); //#000000
$davys-grey2: rgb(75, 75, 75); // #595959
$queen-blue: rgb(73, 97, 156); // #49619C
$gray-x11: rgb(191, 191, 191); //#bfbfbf
$davys-grey1: rgb(89, 89, 89); // #595959
$grape: rgb(123, 51, 184); //#7b33b8
$spartan-crimson: rgb(170, 20, 19); //#aa1413

// Palette Colors
$amazon: rgb(57, 112, 90); // #39705a
$xanadu: rgb(106, 148, 131); // #6a9483
$aero-blue: rgb(207, 236, 225); // #cfece1
$green-ryb: rgb(118, 168, 38); // #76a826
$june-bud: rgb(180, 205, 96); // #b4cd60
$pale-goldenrod: rgb(217, 230, 175); // #d9e6af
$gainsboro: rgb(217, 217, 217); //#d9d9d9
$anti-flash-white: rgb(217, 217, 217); //#d9d9d9

// Toast Colors
$toast-green: rgb(96, 168, 66); // #60a842
$sinopia: rgb(206, 49, 0); // #ce3100
$vivid-amber: rgb(206, 142, 0); // #ce8e00
$true-blue: rgb(0, 95, 206); // #005fce
$kombu-green: rgb(50, 65, 56); // #324138

// Others
$urobilin: rgb(223, 179, 37); // #dfb325
$antique-bronze: rgb(103, 88, 34); // #675822
$plum: rgb(161, 66, 150); // #a14296
$cyber-grape: rgb(87, 58, 116); // #573a74
$bg-white-smoke: rgb(246, 246, 246); // #f6f6f6
$taupe-gray: rgb(140, 140, 140); //#8c8c8c
$gainsboro: rgb(217, 217, 217); // #d9d9d9
$ghost-white: rgb(246, 247, 250); //#f6f7fa
$azureish-white: rgb(219, 224, 237); //#dbe0ed
$gamboge: rgb(221, 155, 6); // #DD9B06
$harvest-gold: rgb(214, 149, 4); // #d69504
$harvest-gold1: rgb(217, 152, 8); // #d99808
$marigold: rgb(231, 166, 22); // #e7a616
$anti-flash-white: rgb(240, 240, 240); // #F0F0F0
$alice-blue: rgb(242, 250, 255); // #f2faff
$tiffany-blue: rgb(0, 191, 178); // #00bfb2;
$medium-persian-blue: rgb(0, 106, 170); //#006aaa
// Overlays
$black0043: rgba($black, 0.043);
$black045: rgba($black, 0.45);
$black0016: rgba($black, 0.016);
$black02: rgba($black, 0.2);
$black015: rgba($black, 0.15);
$black025: rgba($black, 0.25);
$black085: rgba($black, 0.85);
$black012: rgba($black, 0.12);
$black008: rgba($black, 0.08);
$black005: rgba($black, 0.05);
$saffron03: rgba($saffron, 0.3);
$saffron07: rgba($saffron, 0.7);
$blue-yonder03: rgba($blue-yonder, 0.3);
$blue-yonder07: rgba($blue-yonder, 0.7);
$raisin-black03: rgba($raisin-black, 0.3);
$vermilion09: rgba($vermilion, 0.9);
$cerise-pink085: rgba($cerise-pink, 0.85);
$kombu-green01: rgba($kombu-green, 0.1);
$kombu-green03: rgba($kombu-green, 0.3);
$white03: rgba($white, 0.3);
$rich-electric-blue085: rgba($rich-electric-blue, 0.85);

// Linear 
$gamboge-linear: linear-gradient(180deg, $gamboge 8.29%, rgba($harvest-gold, 0.9) 50.66%, rgba($harvest-gold1, 0.6) 86.14%, rgba($marigold, 0) 104.99%);
$black012: rgba($black, 0.12);
$black008: rgba($black, 0.08);
$black005: rgba($black, 0.05);
$black006: rgba($black, 0.06);
