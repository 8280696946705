.a-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;

  &-14 {
    height: rem(14);
    width: rem(14);
  }

  &-15 {
    height: rem(15);
    width: rem(15);
  }

  &-16 {
    height: rem(16);
    width: rem(16);
  }

  &-20 {
    height: rem(20);
    width: rem(20);
  }

  &-24 {
    height: rem(24);
    width: rem(24);
  }

  &-40 {
    height: rem(40);
    width: rem(40);
    @include mobile-down {
      height: rem(24);
      width: rem(24);
    }
  }

  &-72 {
    height: rem(72);
    width: rem(72);
  }

  &-80 {
    height: rem(80);
    width: rem(80);
  }

  &-88 {
    height: rem(88);
    width: rem(88);
  }

  &-email {
    background-image: url('~assets/icons/ic_email.svg');
  }

  &-emailBlack {
    background-image: url('~assets/icons/ic_email_black.svg');
  }

  &-location {
    background-image: url('~assets/icons/ic_location.svg');
  }

  &-phone {
    background-image: url('~assets/icons/ic_phone.svg');
  }

  &-search {
    background-image: url('~assets/icons/ic_search.svg');
  }

  &-zaloWhite {
    background-image: url('~assets/icons/ic_zalo_white.svg');
  }

  &-youtubeWhite {
    background-image: url('~assets/icons/ic_youtube_white.svg');
  }

  &-facebookWhite {
    background-image: url('~assets/icons/ic_facebook_white.svg');
  }

  &-zaloBlack {
    background-image: url('~assets/icons/ic_zalo_black.svg');
  }

  &-youtubeBlack {
    background-image: url('~assets/icons/ic_youtube_black.svg');
  }

  &-facebookBlack {
    background-image: url('~assets/icons/ic_facebook_black.svg');
  }

  &-arrowWhite {
    background-image: url('~assets/icons/ic_arrow_white.svg');
  }

  &-arrowForward {
    background-image: url('~assets/icons/ic_arrow_forward.svg');
  }

  &-arrowPurple {
    background-image: url('~assets/icons/ic_arrow_purple.svg');
  }

  &-arrowForwardBlack {
    background-image: url('~assets/icons/ic_arrow_forward_ios_black.svg');
  }

  &-arrowBackBlack {
    background-image: url('~assets/icons/ic_arrow_back_ios_black.svg');
  }

  &-notiValid {
    background-image: url('~assets/icons/ic_noti-valid.svg');
  }

  &-notiError {
    background-image: url('~assets/icons/ic_noti-error.svg');
  }

  &-loading {
    background-image: url('~assets/icons/ic_loading.svg');
  }

  &-cloudUpload {
    background-image: url('~assets/icons/ic_cloud_upload.svg');
  }

  &-separator {
    background-image: url('~assets/icons/ic_separator.svg');
  }

  &-clock {
    background-image: url('~assets/icons/ic_clock.svg');
  }

  &-close { 
    background-image: url('~assets/icons/ic_close.svg');
  }
  
  &-closeWhite {
    background-image: url('~assets/icons/ic_close_white.svg');
  }

  &-rightQuote {
    background-image: url('~assets/icons/ic_right_quote.svg');
  }

  &-backWhite {
    background-image: url('~assets/icons/ic_back-white.svg');
  }

  &-swapRight {
    background-image: url('~assets/icons/ic_swap_right.svg');
  }

  &-calendar {
    background-image: url('~assets/icons/ic_calendar.svg');
  }

  &-mapPin {
    background-image: url('~assets/icons/ic_map_pin.svg');
  }
}
