@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
  }

  to {
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

@keyframes scaleIn {
  from {
    transform: scale3d(1.04, 1.04, 1.04);
  }

  to {
    opacity: 1;
  }
}

@keyframes move {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeInBlur {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animationFramesLeft {
  0% {
    transform: scale(0.8);
    transform: translate(20%, 0) rotate(0deg) scale(0.8);
  }

  50% {
    transform: scale(1);
    transform: translate(-50%, 0) rotate(8deg) scale(1.4);
  }

  100% {
    transform: scale(1);
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
}

@keyframes animationFramesRight {
  0% {
    transform: scale(0.8);
    transform: translate(-20%, 0) rotate(0deg) scale(0.8);
  }

  50% {
    transform: scale(1);
    transform: translate(50%, 0) rotate(8deg) scale(1.4);
  }

  100% {
    transform: scale(1);
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
}


@keyframes beatSmall {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes scaleX {
  from {
    opacity: 0;
    transform: scaleX(0);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes scaleY {
  from {
    opacity: 0;
    transform: scaleY(0);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

:root {
  --animate-duration: 0.6s;
  --animate-delay: 0.6s;
  --animate-repeat: 1;
}

.preanimate {
  opacity: 0;
}

.animate {
  -webkit-animation-duration: 0.6s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: 0.6s;
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  
  &-fadeInUp {
    animation-duration: 0.6s;
    animation-name: fadeInUp;
    opacity: 0;
  }

  &-slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
  }

  &-slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
  }

  &-zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
  }

  &-zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
  }

  &-scaleOut {
    animation-duration: 1s;
    -webkit-animation-name: scaleOut;
    animation-name: scaleOut;
  }

  &-scaleIn {
    animation-duration: 1s;
    -webkit-animation-name: scaleIn;
    animation-name: scaleIn;
  }
  
  &-move {
    -webkit-animation-name: move;
    animation-name: move;
  }

  &-fadeInBlur {
    -webkit-animation-name: fadeInBlur;
    animation-name: fadeInBlur;
  }

  &-animationFramesLeft {
    -webkit-animation-name: animationFramesLeft;
    animation-name: animationFramesLeft;
  }

  &-animationFramesRight {
    -webkit-animation-name: animationFramesRight;
    animation-name: animationFramesRight;
  }

  &-beatSmall {
    -webkit-animation-name: beatSmall;
    animation-name: beatSmall;
  }

  &-scaleX {
    -webkit-animation-name: scaleX;
    animation-name: scaleX;
  }

  &-scaleY {
    -webkit-animation-name: scaleY;
    animation-name: scaleY;
  }
}

@keyframes tail {
  to { transform: rotate(1turn); }
}
