.t-searchResult {
  $root: &;

  &_searchInput {
    margin: auto;
    max-width: rem(740);
  }

  &_filter {
    margin-bottom: rem(16);
    margin-top: rem(32);

    @include tablet-down {
      margin-top: rem(24);
    }
  }

  &_cateList {
    @include adjust-flex(center, center);
    margin-top: rem(16);
    overflow-x: auto;
    @include scroll-bars(8);

    @include mobile-down {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &-item {
      border: 1px solid $rich-electric-blue;
      border-radius: rem(100);
      cursor: pointer;
      margin: 0 rem(8);
      padding: rem(2) rem(8);

      @include mobile-down {
        margin: 0 rem(4) rem(8);
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.active {
      #{$root}_cateList-item {
        opacity: 0.5;
      }

      #{$root}_cateList-item-active {
        opacity: 1;
      }
    }
  }

  &_amount {
    margin-top: rem(16);
    text-align: center;
  }

  &_result {
    margin-top: rem(16);

    &-titleWrapper {
      margin-bottom: rem(16);
      @include adjust-flex(space-between, center);

      @include mobile-down {
        align-items: flex-start;
        flex-direction: column-reverse;
      }

      * {
        flex: 1;
      }
    }

    &-title {
      flex: 2;

      @include mobile-down {
        align-self: center;
      }
    }

    &-detail {
      margin-top: rem(32);
      padding-bottom: rem(36);
  
      @include mobile-down {
        margin-top: rem(24);
        padding-bottom: rem(24);
      }
    }
  }

  &_list {
    @include adjust-flex(stretch, center);
    flex-wrap: wrap;
    margin: rem(-16);
    
    @include tablet-down {
      margin: rem(-8);
    }

    @include mobile-down {
      flex-direction: column;
    }

    &-item {
      flex: 0 0 calc(100% / 3);
      padding: rem(16);

      @include tablet-down {
        padding: rem(8);
      }

      @include mobile-down {
        flex: 0 0 100%;
      }
    }
  }

  &_pagination {
    margin-top: rem(40);

    @include tablet-down {
      margin-top: rem(32);
    }

    @include mobile-down {
      margin-top: rem(24);
    }
  }

  &_other {
    margin-top: rem(32);
    padding-bottom: rem(36);

    @include mobile-down {
      margin-top: rem(24);
      padding-bottom: rem(24);
    }

    &-title {
      margin-bottom: rem(16);
    }

    &-item {
      background-color: $snow;
      border-radius: rem(12);
      margin-bottom: rem(8);
      padding: rem(16);

      &:last-child {
        margin-bottom: 0;
      }

      &-title,
      &-desc {
        @include text-overflow(1);
      }

      &-title {
        margin-bottom: rem(8);
      }
    }
  }
}
