// fonts
$font-path: '~assets/fonts/';
$font-iciel: 'iCiel Pacifico', serif;
$font-montserrat: 'Montserrat', sans-serif;

// transition
$transition-fast: 0.1s;
$transition: 0.25s;
$transition-long: 0.4s;

// z-index
$z-1: 1;
$z-header: 100;
$z-modal: 101;
$z-loading: 104;

// viewport
$standard-viewport: 1366px;

//height header
$header-main-desktop: 80;
$header-main-tablet: 65;
$header-sub-desktop: 48;

// size screen
$width-container-value: 1126;
$width-pc-medium-value: 1366;
$width-container: 1126px;
$width-pc-medium: 1366px;
