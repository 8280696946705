.t-companyInfo {
  background: $snow;
  border-radius: rem(12);
  overflow: hidden;
  @include adjust-flex(flex-start, stretch);

  @include tablet-down {
    display: block;
  }

  &_heading {
    margin-bottom: rem(14);
  }

  &_content {
    flex: 0 0 calc(676 / 1126 * 100%);
    padding: rem(28) rem(48);

    @include desktop-down {
      padding: rem(24);
    }
    @include mobile-down {
      padding: rem(16);
    }
  }

  &_description {
    margin-bottom: rem(28);

    @include tablet-down {
      margin-bottom: rem(20);
    }
  }

  &_infoCompany {
    background: $white;
    margin-top: rem(20);
    padding: rem(20) rem(20) rem(20) rem(18);
    position: relative;
    @include adjust-flex(flex-start, flex-start);

    &::before {
      border-radius: rem(8) 0 0 rem(8);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: rem(4);
    }

    @include desktop-down {
      padding: rem(16);
    }

    @include tablet-down {
      flex-wrap: wrap;
      margin-top: rem(16);
      padding: rem(12);
    }

    &:nth-child(n)::before {
      background-color: $cerise-pink;
    }

    &:nth-child(2n)::before {
      background-color: $saffron;
    }

    &:nth-child(3n)::before {
      background-color: $rich-electric-blue;
    }
  }

  &_title {
    flex-shrink: 0;
    margin-right: rem(12);
    @include adjust-flex(flex-start, flex-start);

    @include tablet-down {
      margin-right: rem(8);
    }

    .a-icon {
      margin-right: rem(12);

      @include tablet-down {
        margin-right: rem(8);
      }
    }
  }

  &_wrapMap {
    flex: 0 0 calc(450 / 1126 * 100%);
    width: 100%;

    @include tablet-down {
      flex: 0 0 100%;
      height: rem(450);
    }
  }

  &_map {
    height: 100%;

    &_marker {
      @include adjust-flex(flex-start, flex-start);
    }

    &_text {
      color: $spartan-crimson;
      min-width: rem(200);
      @include font-base(10, 20);

      @include mobile-down {
        @include font-base(8, 16);
      }
    }
  }
}
