.a-input {
  $root: &;
  position: relative;

  &-disabled {
    #{$root}_input {
      background: $snow;
    }
  }

  &-error {
    #{$root}_input {
      border-color: $sinopia;
      color: $sinopia;
    }
  }

  &-search {
    #{$root}_input {
      border-radius: rem(100);
      padding: rem(4) rem(40) rem(4) rem(12);
    }
  }

  &_label {
    color: $white-smoke;
    margin-bottom: rem(8);
    @include font-base(12, 16);

    &-required {
      color: $white-smoke;
      margin-left: rem(4);
    }

    &-error {
      color: $sinopia;
    }
  }

  &_wrap {
    position: relative;
  }

  &_input {
    appearance: none;
    background: $white;
    border: 1px solid $gainsboro;
    border-radius: rem(8);
    color: $raisin-black;
    font: inherit;
    font-weight: 400;
    margin: 0;
    outline: none;
    padding: rem(4) rem(12);
    transition: all $transition linear;
    width: 100%;
    @include font-base(14, 22);

    &::placeholder {
      color: $taupe-gray;
      font-weight: 400;
      @include font-base(14, 22);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  &_searchBtn {
    background: transparent;
    border-bottom: 0;
    border-left: 1px solid $gainsboro;
    border-right: 0;
    border-top: 0;
    cursor: pointer;
    height: 100%;
    padding: rem(8);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .a-icon {
      height: 100%;
    }
  }

  &_message {
    &-error {
      color: $sinopia;
      @include font-base(12, 16);
      
      &-white {
        color: $white;
      }
    }
  }
}
