.a-text {
  margin: 0;
  transition: $transition ease;
  @include font-base(16, 24);
  @include mobile-down {
    @include font-base(14, 22);
  }

  &-20x30 {
    @include font-base(20, 30);
    @include mobile-down {
      @include font-base(16, 24);
    }
  }

  &-14x22 {
    @include font-base(14, 22);
    @include mobile-down {
      @include font-base(12, 20);
    }
  }

  &-12x16 {
    @include font-base(12, 16);
  }

  &-inline {
    display: inline-block;
  }

  @include style-color();
  @include style-font-weight();
  @include style-general();
}
