.o-tabs {
  $root: &;

  &_labels {
    border-bottom: 1px solid $black006;
    border-radius: rem(3);
    overflow-x: auto;
    position: relative;
    @include adjust-flex(center, center);
    @include scroll-bars(8);
    
    @include mobile-up {
      margin: 0 rem(-16);
    }
  }

  &_label {
    color: $black085;
    font-weight: 500;
    white-space: nowrap;
    @include font-base(14, 22);
  }

  &_tab {
    cursor: pointer;
    margin-left: rem(16);
    margin-right: rem(16);
    padding-bottom: rem(12);
    padding-top: rem(12);
    position: relative;

    @include mobile-down {
      margin-left: rem(8);
      margin-right: rem(8);
      padding-bottom: rem(8);
      padding-top: rem(8);
    }

    &::after {
      bottom: 0;
      content: '';
      height: rem(2);
      left: 50%;
      opacity: 0;
      position: absolute;
      transform: translateX(-50%);
      transition: all $transition linear;
      visibility: hidden;
      width: 0;
      z-index: 1;
    }

    &:hover,
    &-active {
      #{$root}_label {
        color: $rich-electric-blue;
        font-weight: 500;
      }

      &::after {
        border-bottom: 2px solid $rich-electric-blue;
        opacity: 1;
        visibility: visible;
        width: 100%;
      }
    }
  }

  &_panel {
    display: none;

    &-active {
      display: block;
      margin-top: rem(20);
    }
  }
}
