.a-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-inherit {
    img {
      object-fit: inherit;
    }
  }

  &-initial {
    img {
      object-fit: initial;
    }
  }

  &-contain {
    img {
      object-fit: contain;
    }
  }

  &-1x1 {
    @include aspect-ratio(1, 1);
  }

  &-logo {
    @include aspect-ratio(103, 55);
  }

  &-4x3 {
    @include aspect-ratio(4, 3);
  }

  &-16x9 {
    @include aspect-ratio(16, 9);
  }

  &-148x70 {
    @include aspect-ratio(148, 70);
  }

  &-161x107 {
    @include aspect-ratio(161, 107);
  }

  &-home-banner {
    @include aspect-ratio(1366, 600);

    @include tablet-down {
      @include aspect-ratio(2, 1);
    }

    @include mobile-down {
      @include aspect-ratio(1, 1);
    }
  }

  &-105x55 {
    @include aspect-ratio(105, 55);
  }

  &-740x461 {
    @include aspect-ratio(740, 461);
  }

  &-258x174 {
    @include aspect-ratio(258, 174);
  }

  &-548x370 {
    @include aspect-ratio(548, 370);
  }
  
  &-banner {
    @include aspect-ratio(1366, 600);
  }

  &-1366x400 {
    @include aspect-ratio(1366, 400);
  }

  &-257x160 {
    @include aspect-ratio(257, 160);
  }
}
