.o-pagination {
  $root: 7;
  @include adjust-flex;
  height: auto;
  width: 100%;

  li {
    @include adjust-flex;

    a {
      @include adjust-flex;
      height: 100%;
      outline: none;
      width: 100%;
    }
  }

  &_page,
  &_prev,
  &_next {
    height: rem(32);
    width: rem(32);

    @include mobile-down {
      height: rem(24);
      width: rem(24);
    }
  }

  &_page {
    border: 1px solid $gainsboro;
    border-radius: 50%;
    color: $black085;
    height: rem(32);
    margin-left: rem(4);
    margin-right: rem(4);
    min-width: rem(32);
    transition: all $transition linear;
    @include adjust-flex;

    &:not(.disabled):hover,
    &-active {
      background-color: $white;
      border: 1px solid $saffron;
      border-radius: 50%;
      color: $saffron;
    }

    a {
      font-weight: 400;
      @include font-base(14, 22);

      @include mobile-down {
        @include font-base(12, 20);
      }
    }
  }

  &_prev,
  &_next {
    background-color: $white;
    border: 1px solid $gainsboro;
    border-radius: 50%;

    a {
      padding: rem(10);

      @include mobile-down {
        padding: rem(8);
      }
    }
  }

  &_prev {
    margin-right: rem(4);
  }

  &_next {
    margin-left: rem(4);
  }

  .disabled {
    background-color: $snow;
    border: 1px solid $gray-x11;

    a {
      cursor: not-allowed;
    }
  }
}
