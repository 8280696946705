.a-text-area {
  &_label {
    color: $white-smoke;
    margin-bottom: rem(8);
    @include font-base(12, 16);

    &-required {
      color: $white-smoke;
      margin-left: rem(4);
    }

    &-error {
      color: $sinopia;
    }
  }

  &_errorMessage {
    color: $sinopia;
    margin-top: rem(4);
    @include font-base(12, 18);
    
    &-white {
      color: $white;
    }
  }

  &_inputele {
    border: rem(1) solid $gainsboro;
    border-radius: rem(8);
    color: $raisin-black;
    font: inherit;
    margin: 0;
    outline: none;
    padding: rem(5) rem(12);
    resize: none;
    transition: all $transition linear;
    width: 100%;
    @include font-base(14, 22);

    &::placeholder {
      color: $taupe-gray;
    }

    &-error {
      border-color: $sinopia;
    }
  }
}
