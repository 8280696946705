.t-productCard {
  height: 100%;
  margin-left: rem(16);
  margin-right: rem(16);
  position: relative;

  @include tablet-down { 
    margin-left: rem(8);
    margin-right: rem(8);
  }

  &_img {
    left: 50%;
    margin: auto;
    max-width: rem(140);
    position: absolute;
    top: rem(-70);
    transform: translateX(-50%);
    width: 100%;
    @include small-mobile { 
      max-width: rem(90);
    }
  }

  &_title {
    @include text-overflow(3);
    margin-bottom: rem(20);
  }

  &_content {
    background: $snow;
    border-radius: rem(12);
    box-shadow: 0 rem(3) rem(6) rem(-4) $black012, 0 rem(6) rem(16) $black008, 0 rem(9) rem(28) rem(8) $black005;
    height: 100%;
    padding: rem(98) rem(32) rem(34) rem(50);
    transition: all $transition linear;

    &:hover {
      box-shadow: 0 rem(3) rem(6) rem(-4) rgba($black, 0.22), 0 rem(6) rem(16) rgba($black, 0.18), 0 rem(9) rem(28) rem(8) rgba($black, 0.25);
    }
    @include small-mobile {
      padding: rem(60) rem(20) rem(20) rem(45);
    }
  }

  &_description {
    margin-top: rem(16);

    ul {
      list-style: none;
      margin: rem(-8);
      
      li {
        padding: rem(8);
      }
    }
  }
}

.t-productModel {
  &_carousel {
    .slick-prev {
      top: calc(50% + 40px);
    }

    .slick-next {
      top: calc(50% + 40px);
    }

    .slick-list {
      margin: 0 rem(-16) rem(-16);
      padding-bottom: rem(40);
      padding-top: rem(110);

      @include tablet-down { 
        margin-left: rem(-8);
        margin-right: rem(-8);
        padding-top: rem(90);
      }
    }

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;
  
      > div,
      .o-carousel_wrap,
      .o-carousel_item {
        height: 100%;
      }
    }

    .slick-dots {
      @include mobile-down {
        bottom: 0;
      }
    }
    
    @include desktop-down {
      .slick-prev {
        left: rem(-35);
      }

      .slick-next {
        right: rem(-38);
      }
    }
  }
}
