.t-banner {
  .o-carousel {
    &_dotBanner {
      background-color: $white;
      border-radius: rem(1);
      display: block;
      height: rem(3);
      opacity: 0.3;
      transition: $transition-long ease;
      width: rem(16);
    
    }

    .slick-active {
      background-color: $white;
      border-radius: rem(4);
      width: rem(24);
    }
  
    .slick-dots {
      bottom: rem(40);
      margin-top: 0;

      @include mobile-down {
        bottom: rem(25);
      }
    }
  }

  &_image {
    &.hasLink {
      .a-image {
        transition: all 0.4s ease-in-out;

        &:hover {
          transform: scale(1.025);
        }
      }

    }

    .a-image {
      height: 100%;
      width: 100%;
    }

    .a-link {
      &:hover {
        opacity: unset;
      }
    }
  }
}
