.t-mainLayout {
  padding-top: rem($header-main-desktop + $header-sub-desktop);
  @include tablet-down {
    padding-top: rem($header-main-tablet);
  }

  &-home {
    min-height: calc(100vh - #{$header-main-desktop + $header-sub-desktop}px);
    @include tablet-down {
      min-height: calc(100vh - #{$header-main-tablet}px);
    }
  }
}
