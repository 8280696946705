.t-recruitModal {
  position: relative;

  &_layer {
    background-image: url('~assets/images/jd_modal_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    @include mobile-down {
      background-size: 100%;
    }
  }

  &_footer {
    background-color: $medium-persian-blue;
    padding: rem(13) rem(60);
    @include adjust-flex(space-between, center);
    
    @include tablet-down {
      padding: rem(13) rem(32);
    }

    @include mobile-down {
      padding: rem(13) rem(16);
    }

    &-back {
      cursor: pointer;
      @include adjust-flex();

      .a-text {
        margin-left: rem(8);
      }
    }
  }

  &_inputGroup {
    margin-bottom: rem(20);
    margin-left: rem(-16);
    margin-right: rem(-16);
    @include adjust-flex(flex-start, flex-start);

    @include mobile-down {
      flex-direction: column;
      margin-bottom: 0;
    }

    &-field {
      flex: 0 0 calc(100% / 2);
      padding: 0 rem(16);

      @include mobile-down {
        flex: 0 0 100%;
        margin-bottom: rem(12);
        width: 100%;
      }
    }
  }
}
