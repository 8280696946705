.t-relatedNews {
  &_list {
    margin: 0 rem(-16);
    @include tablet-down {
      margin: 0 rem(-8);
    }

    &-item {
      height: 100%;
      padding: rem(16);

      @include tablet-down {
        padding: rem(16) rem(8);
      }
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    > div,
    .o-carousel_wrap,
    .o-carousel_item {
      height: 100%;
    }
  }
}
