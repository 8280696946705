.t-partners {
  &_description {
    margin-top: rem(24);

    @include mobile-down {
      margin-top: rem(16);
    }
  }

  &_carousel {
    margin-top: rem(52);

    .slick-list {
      margin-left: rem(-16);
      margin-right: rem(-16);
    }

    @include desktop-down {
      .slick-prev {
        left: rem(-48);
      }

      .slick-next {
        right: rem(-48);
      }
    }
    @include tablet-down {
      margin-top: rem(32);

      .slick-list {
        margin-left: rem(-8);
        margin-right: rem(-8);
      }

      .slick-prev {
        left: rem(-40);
      }

      .slick-next {
        right: rem(-40);
      }
    }

    @include mobile-down {
      margin-top: rem(24);
    }
  }

  &_image {
    border: 1px solid $gainsboro;
    border-radius: rem(12);
    cursor: pointer;
    margin-left: rem(16);
    margin-right: rem(16);
    overflow: hidden;
    transition: all $transition linear;

    @include tablet-down {
      margin-left: rem(8);
      margin-right: rem(8);
    }

    &:hover {
      box-shadow: 0 rem(3) rem(6) rem(-4) $black012, 0 rem(6) rem(16) $black008, 0 rem(9) rem(28) rem(8) $black005;
    }
  }
}
