.t-recruitForm {
  background-color: $tiffany-blue;
  box-shadow: 0 0 rem(24) rem(1) rgba($kombu-green, 0.1);
  overflow-y: hidden;
  padding: rem(40) rem(24);

  @include tablet-down {
    padding: rem(24) rem(16);
  }

  &_form {
    background-image: url('~assets/images/register_bg.png');
    background-position: center, center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    max-width: rem(620);
    width: 100%;
  }

  &_desc {
    margin-bottom: rem(16);
    margin-top: rem(8);
  }

  &_inputGroup {
    margin-bottom: rem(20);
    margin-left: rem(-16);
    margin-right: rem(-16);
    @include adjust-flex(flex-start, flex-start);

    @include mobile-down {
      flex-direction: column;
      margin-bottom: 0;
    }

    &-field {
      flex: 0 0 calc(100% / 2);
      padding: 0 rem(16);

      @include mobile-down {
        flex: 0 0 100%;
        margin-bottom: rem(12);
        width: 100%;
      }
    }
  }

  &_textarea {
    margin-bottom: rem(20);

    @include mobile-down {
      margin-bottom: rem(12);
    }
  }

  &_btn {
    margin-top: rem(32);
    @include  adjust-flex();

    @include mobile-down {
      margin-top: rem(24);
    }
  }
}
