.m-fileInput {
  $root: &;
  
  &_pending {
    margin-bottom: rem(8);
  }

  &_content {
    background-color: $white;
    border: 1px solid $gainsboro;
    border-radius: rem(8);
    flex-direction: column;
    padding: rem(18) rem(19) rem(18);
    @include adjust-flex(center, center)  ;
  }

  &_fileName {
    @include text-overflow(2);

    .a-text {
      word-break: break-all;
    }
  }

  &_inputfile {
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }

  &_label {
    border: 1px dashed $gainsboro;
    cursor: pointer;
    margin-top: rem(12);
    padding-left: rem(8);
    padding-right: rem(8);
    @include adjust-flex(center, center);

    .a-icon {
      margin-right: rem(8);
    }
  }

  &_errorMessage {
    color: $sinopia;
    margin-top: rem(4);
    @include font-base(12, 18);
  }

  &-error {
    #{$root}_label {
      border-color: $sinopia;
    }
  }
}
